<template>
  <MainModal v-if="errorModal" :on-close="handleCloseErrorModal" :modal-center="true">
    <div class="error__confirm">
      <div class="error__confirm-title">Token is out of date or you do not have access!</div>
      <div class="error__confirm-buttons">
        <div class="error__confirm-button">
          <MainButton @click.native="handleReLogin" :button-cancel="true">RE-LOGIN</MainButton>
        </div>
      </div>
    </div>
  </MainModal>
</template>

<script>
import MainButton from "../atoms/buttons/MainButton.vue";
import MainModal from "../molecules/MainModal.vue";

export default {
  data() {
    return {};
  },
  props: {
    errorModal: {
      type: Boolean,
      default: false,
    },
    handleCloseErrorModal: {
      type: Function,
      default() {
        return {};
      },
    },
  },
  components: {
    MainButton,
    MainModal,
  },
  methods: {
    handleReLogin() {
      window.location.href = `/login`;
    },
  },
};
</script>

<style lang="scss">
.error {
  &__confirm {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__confirm-title {
    padding-top: 30px;
    font-size: 26px;
    text-align: center;
  }

  &__confirm-buttons {
    margin-top: auto;
    padding-top: 20px;
    display: flex;
    justify-content: center;
  }

  &__confirm-button {
    width: 48%;
  }
}
</style>
